/* emblemSteps.css */

.emblem-steps {
    background-color: #141414; /* Black background */
    padding-top: 10px;
    padding: 50px;
}

.section3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    margin: 20px; /* Adjust margin as needed */
}

.pic{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}

.step1,
.step2,
.step3 {
    flex: 1;
    text-align: center;
    padding: 20px;
}

.step1 img{
    width: 100%;
    max-width: 200px; /* Adjust max-width as needed for the first image */
    height: auto;
}

.step2 img {
    width: 100%;
    max-width: 300px; /* Adjust max-width as needed for the second image */
    height: auto;
}

.step3 img {
    width: 100%;
    max-width: 200px; /* Adjust max-width as needed for the third image */
    height: auto;
}

.text { 
    display: flex;
    flex-direction: row;
   
    max-width: 400px; /* Set max-width to 100% to ensure it doesn't exceed the image width */
    margin-left: auto; /* Center text */
    margin-right: auto; /* Center text */
}




h1 {
    font-size: 4rem; /* Adjust font size as needed */

}

.text h2 {
    margin-top: 12%;
    margin-left: 5%;
    text-align: left;
    font-weight: 300;
    font-size: 20px;
}



/* Media query for screens smaller than 790px */
@media screen and (max-width: 790px) {
    .section3 {
        flex-direction: column;
    }
    .pic{
        margin-top: 0px;
        margin-bottom: 0px;
        height: fit-content;
    }

    .step1 .pic img{
        width: 80%; /* Make the images fill the container */
        max-width: none; /* Remove max-width to allow images to shrink */
        height: auto;

    }
    .step2 .pic img{
        width: 120%; /* Make the images fill the container */
        max-width: none; /* Remove max-width to allow images to shrink */
        height: auto;
    }
    .step3 .pic img {
        width: 100%; /* Make the images fill the container */
        max-width: none; /* Remove max-width to allow images to shrink */
        height: auto;
        
    }

    /* Set a fixed width for each image container */
    .step1 .pic,
    .step2 .pic,
    .step3 .pic {
        max-width: 200px; /* Adjust max-width as needed for the image containers */
        margin: 0 auto; /* Center the images within the containers */
    }
}
