body {
}

.section_padding{
    padding: 4rem 4rem;
}

.footer{
    background-color: #141414;
    width: 100%;
}

.sb_footer{
    display: flex;
    flex-direction: column;
}

.sb_footer-links{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}

.sb_footer-links-div{
    width: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    color: #faf7f7;
    align-items: center;

}

a{
    color: rgb(175, 175, 179);
    margin-left: 5px;
    margin-right: 5px;
}

.socialmedia {
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialmedia img {
    width: 30px; /* Adjust the width according to your preference */
    margin-right: 10px; /* Add some margin to the right */
    margin-left: 10px;
}

.logo img {
    width: 150px; /* Adjust the width according to your preference */
}

.sb__footer-links-div p{
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 0.5rem 0;
    cursor: pointer;
}

.sb_footer-bellow{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0.2rem;
    width: 100%;
    align-items: center;
}

.sb_footer-bellow-links{
    display: flex;
    flex-direction: row;
}

.sb_footer-bellow-links p{
    font-size: 13px;
    line-height: 15px;
    color: #faf7f7;;
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
}


.sb_footer-copyright p{
    font-size: 13px;
    line-height: 15px;
    color: #faf7f7;
    font-weight: 600;
}

/* Media query for screens smaller than 768px */
@media screen and (max-width: 790px) {
    .footer{
        background-color: #141414;
        width: 100%;
    }
    .sb_footer-links {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .sb_footer-links-div {
        margin-top: 1rem;
    }

    .sb_footer-bellow-links {
        flex-direction: row;
    }
}