/* join.css */

.join {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #141414;
    padding: 50px;
  }
  
  .join h1 {
    color: white;
    text-align: center;
  }
  
  .input-group {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  .input-group label {
    margin-bottom: 5px;
    color: white;
    text-align: start;
    font-size: 22px; /* Adjust the size of the label */
  }
  
  input {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    border-radius: 0;
    margin-bottom: 20px; /* Add margin between input groups */
  }
  
  input[type='submit'] {
    background-color: black; /* Set a background color for the submit button */
    color: white;
    border: 2px solid white; /* Add a white border */
    padding: 10px 20px; /* Add padding to the submit button */
    cursor: pointer;
    width: 60%;
  }
  
  /* Style for the submitted state */
  .join.submitted input[type='submit'] {
    background-color: black; /* Change the background color when submitted */
    cursor: not-allowed; /* Disable the cursor when submitted */
  }
  
  /* Media query for screens smaller than 768px */
  @media screen and (max-width: 790px) {
    .join {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .join h1 {
      font-size: 42px;
      text-align: center;
      padding: 10px;
    }
  
    .email {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    input {
      margin-bottom: 20px;

    }

    .input-group {
        width: 200%;
        display: flex;
        flex-direction: column;
        align-items: start;
      }

      input[type='submit'] {
        background-color: black; /* Set a background color for the submit button */
        color: white;
        border: 2px solid white; /* Add a white border */
        padding: 10px 20px; /* Add padding to the submit button */
        cursor: pointer;
        width: 200%;
      }
      
      /* Style for the submitted state */
      .join.submitted input[type='submit'] {
        background-color: black; /* Change the background color when submitted */
        cursor: not-allowed; /* Disable the cursor when submitted */
        width: 200%;

      }
  }
  