.about-us-container {
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column; /* Ensure children are stacked vertically */
    align-items: center; /* Center children horizontally */
    background-color: #141414;

  }
  
  .members {
    flex: 1;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center; /* Center children horizontally */
  }
  
  .About-title {
    margin-bottom: 60px;
    margin-top: 0px;
    color: white;
  }

  .About-title h1{
    font-size: 36px;
  }
  .about-us-container .About-title {
    color: white; /* Update the color to white */
  }
  
  
  .team-member {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .member-info {
    text-align: center;
    width: auto; /* Set width to auto to fit content */

  }
  
  .member-picture {
    width: 220px;
    height: 220px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .member-name {
    font-size: 32px;
    white-space: nowrap; /* Prevent name from wrapping */
    color: white;

  }
  
  .member-role {
    font-size: 24px;
    color: white;
  }
  
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    text-align: left;
    margin-left: 30px; /* Adjust margin as needed */
    width: 300px;
    font-weight: 300;

    
  }

  
  .linkedin-icon {
    width: 30px;
    height: 30px;
    margin-top: 10px; /* Add margin-top to move the icon to the bottom */
    margin-left: 5px;
  }
  

  /* Media query for screens smaller than 768px */
@media screen and (max-width: 790px) {
  .members{
    flex: 1;
    flex-direction: column;
    display: flex;  
  }
    .team-member {
      display: flex;
      flex: 1;
      align-items: center;
      flex-direction: column;
      margin-bottom: 20px;
    }

  .info{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0px;
  }
    
}