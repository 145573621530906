:root {
  --black: #141414;
  --white: #faf7f7;
  --red: #bd1f36;
  --green: #00ff00;
}

body {
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */

}


.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--black);
  color: var(--black);
  padding: 0px 20px;
  width: 100%; /* Make the navigation bar full width */
  position: fixed; /* Fixed position to keep it at the top */
  top: 0; /* Stick it to the top */
  z-index: 100; /* Ensure it's above other content */

}

.logo img {
  max-width: 80px;
  max-height: 80px;
}

.center-buttons {
  display: flex;
}

.center-buttons button {
  margin-right: 40px;
  margin-left: 4 0px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 16px;
  font-weight: lighter;
  color: var(--white);
  white-space: nowrap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

  
}

.sign-up {
  margin-right: 60px; /* Add more separation between the button and the right side */
}

/* Add the following styles for the modal and blur effect */
.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 1;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 350px; 
  height: 460px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}


.sign-up button {
  background-color: var(--black);
  color: var(--white);
  border: 2px solid var(--white); 
  padding: 10px 20px;
  border-radius: 0;
  font-weight: 500;

}

/*.center-buttons.sign-up button:hover {
  background-color: var(--white);

}*/

@media screen and (max-width: 768px) {
  .logo {
    order: -1; /* Move the logo to the left by changing the order */
  }

  .menu-button {
    display: flex;
    order: 1; /* Move the menu button to the right by changing the order */
  }

  .center-buttons {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background-color: var(--white);
    position: absolute;
    top: 60px;
    left: 0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }

  .center-buttons.show {
    display: none;
  }

  .center-buttons button {
    margin: 10px 0;
  }
}
