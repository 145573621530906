.testimonials-container {
    text-align: center;
    padding: 30px; /* Increased padding */
    display: flex;
    flex-direction: column; /* Ensure children are stacked vertically */
    align-items: center; /* Center children horizontally */
    background-color: #141414;

  }
  
  .Testimonials-title {
    margin-bottom: 30px; /* Increased margin */
    color: white;
  }
  .Testimonials-title h1{
    font-size: 36px;
  }
  
  .testimonials-container .Testimonials-title {
    color: white; /* Update the color to white */
  }
  .testimonial {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1000px; /* Increased maximum width */
    margin: 0 auto; /* Center the testimonial horizontally */
    color: white;
  }
  
  .testimonial-author {
    display: flex;
    flex-direction: column; /* Stack author info and text vertically */
    align-items: flex-start; /* Align author info to the left */
  }
  
  .author-info {
    text-align: left;
  }
  
  .author-name {
    font-size: 32px; /* Increased font size */
    margin-bottom: 10px; /* Increased margin */
  }
  
  .author-role {
    font-size: 20px; /* Increased font size */
    color: white;
    margin: 0;
  }
  
  .linkedin-icon {
    width: 40px; /* Increased icon size */
    height: 40px; /* Increased icon size */
    margin-right: 10px;
  }
  
  .author-picture {
    width: 220px; /* Increased image width */
    height: 220px; /* Increased image height */
    margin-top: 30px;
    margin-right: 50px;
  }
  
  .testimonial-text {
    width: 100%; /* Full width for the text */
    text-align: left;
  }
  
  .testimonial-text p {
    font-size: 18px; /* Increased font size */
    margin-bottom: 15px; /* Increased margin */

    font-weight: 300;
  }
  
  a {
    font-size: 18px; /* Increased font size */
    color: #007bff;
    text-decoration: none;
    margin: 0px;


  }
  
  a:hover {
    text-decoration: underline;
  }
  


 /* Media query for screens smaller than 790px */
@media screen and (max-width: 790px) {

  .testimonial {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the entire testimonial content */
    text-align: center; /* Center the text within the container */
  }

  .author-picture {
    margin: 0 auto; /* Center the image horizontally */
  }

  .testimonial-author {
    display: flex;
    flex-direction: column; 
    align-items: center; 
  }

  .author-info {
    text-align: center;
  }

  .testimonial-text {
    text-align: center;
  }
}
