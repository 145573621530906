.background-image {
  position: relative; 
  background-image: url('../../../assets/samarkand.jpeg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center; 
}

.background-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% transparency */
  z-index: 1; 
}

.containers {
  position: relative; 
  width: 100%;
  z-index: 2; 
}

.container1 {
  font-size: 36px;
  text-align: center;
  margin-top: 30px;
  color: #fff; 
}

.container2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  text-align: center; 
  margin: 0 20px; 
  font-size: 16px;
}

.container1 h2 {
  font-size: 22px;
  color: #fff;
  text-align: center;
  margin: 0;
  letter-spacing: 1px; /* Adjust the letter spacing as needed */
}


.sign-up-Intro {
  margin-top: 22px;
}

.sign-up-Intro button {
  background-color: var(--black);
  color: var(--white);
  border: 2px solid var(--white); 
  padding: 10px 20px;
  border-radius: 0;
  font-weight: 500;
  font-weight: lighter;
}
/* Media query for screens smaller than 768px */
@media screen and (max-width: 790px) {


  .containers {
    flex-direction: column;
    align-items: center;
  }

  .container1,
  .container2 {
    margin-left: 50px;
    margin-right: 50px;
    text-align: center;
  }

  .container1 h2 {
    text-align: center;
    font-size: medium;
    padding: 0px;
    margin: 0px;
  }

  .sign-up-Intro {
    margin-top: 10px;
    margin-right: 0;
    justify-content: center; 
    align-self: center;
  }

}