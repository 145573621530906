
.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #141414;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .section-title {
    font-size: 28px;
    color: white;
    margin-bottom: 20px;
  }
  
  .privacy-content {
    font-size: 16px;
    color: white;
  }
  
  .privacy-content p {
    margin-bottom: 15px;
  }
  
  .privacy-content h2 {
    font-size: 24px;
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-content strong {
    font-weight: bold;
  }
  
  /* Add more styling based on your design preferences */
  