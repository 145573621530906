.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #141414;
}

.section1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.section-image1 {
  flex: 1;
  display: flex;
  align-items: center;
  width: 30%;
}

.section-image1 img {
  width: 10%;
  height: auto;
  flex: 1;
}


.section2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  margin-left: 100px;
}

.section-image2 {
  flex: 1;
}

.section-image2 img {
  width: 80%;
  height: auto;
}

.section-text {
  flex: 1;
  padding: 0 20px;
}
.section-text h1{
  font-size: 36;
}
.section-text h2 {
  font-size: 28px;
  letter-spacing: 1px;
  font-weight: 300;


}

/* Media query for screens smaller than 768px */
@media screen and (max-width: 790px) {
  .section2 {
    flex-direction: column;
    margin: 0px;
    align-items: center;

  }

  .section-text h1 {
    font-size: 30px;
    /* Adjust the font size to your preference */
    font-weight: bold;
    text-align: center;
  }

  .section-text h2 {
    font-size: 22px;
    /* Adjust the font size to your preference */
    font-weight: 300;
    text-align: center;
  }

  .section-image2 img {
    width: 60%;
    height: auto;
    align-items: center;
    text-align: center;
  }

  .section-image2 {
    align-items: center;
    text-align: center;
  }

}